<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          fixed-tabs
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview></user-tab-overview>
          </v-tab-item>
          <v-tab-item>
            <user-tab-permissions></user-tab-permissions>
          </v-tab-item>
          <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <!-- <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item> -->
          <!-- <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'
import userStoreModule from '../userStoreModule'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabPermissions from './user-tab-permissions/UserTabPermission.vue'
import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'

export default {
  components: {
    UserTabOverview,
    UserTabPermissions,
    UserTabNotifications,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref({})
    const userTab = ref(null)
    store
      .dispatch('app-user/fetchUser', { uuid: router.currentRoute.params.uuid })
      .then(response => {
        userData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Permission' },
      { icon: mdiLockOutline, title: 'Notification' },
    ]

    return {
      tabs,
      userTab,
      userData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
