<template>
  <div class="user-tab-overview">
    <v-card class="mb-7">
      <v-card-title>
        User Detail
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Username:</span>
            <span class="text--secondary">{{ user.name }}</span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium text-no-wrap me-2">Email:</span>
            <span class="text--secondary">{{ user.email }}</span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Status:</span>
            <span class="text--secondary">
              <v-chip
                small
                label
              >
                {{ user.status }}
              </v-chip>
            </span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Role:</span>
            <span class="text--secondary text-capitalize">{{ user.role }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Company Name: </span>
            <span class="text--secondary">{{ user.details.name }}</span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Company Email</span>
            <span class="text--secondary">{{ user.details.email }}</span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Company Contact:</span>
            <span class="text--secondary">{{ user.details.phone }}</span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Company Address:</span>
            <span class="text--secondary">{{ user.details.address }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- <invoice-list></invoice-list> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      user: {
        uuid: '',
        name: '',
        email: '',
        status: '',
        details: {
          uuid: '',
          name: '',
          email: '',
          phone: '',
          address: '',
          logo: '',
        },
        resolveUserStatusVariant: '',
      },
    }
  },
  mounted() {
    this.get(this.$route.params.uuid)
  },
  methods: {
    async get(uuid) {
      await this.$services.ApiServices.get('user', uuid).then(
        ({ data }) => {
          this.user = data.data
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
